import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { graphql, StaticQuery } from "gatsby";

import Hero from "../../components/landingPage/Hero";
import Layout from "../../components/Layout";
import Cta from "../../components/Cta";

import Location from "../../components/landingPage/Location";
import PriceList from "../../components/landingPage/PriceList";
import Reviews from "../../components/landingPage/Reviews";
import Dermoscopy from "../../components/landingPage/Dermoscopy";
import Oncology from "../../components/landingPage/Oncology";

import Surgery2 from "../../components/landingPage/Surgery2";
import Laser from "../../components/landingPage/Laser";
import Melanoma from "../../components/landingPage/Melanoma";

import Gallery from "../../components/landingPage/Gallery";
import Offer from "../../components/landingPage/Offer";
import Faq from "../../components/landingPage/Faq";

import Covid from "../../components/landingPage/Covid";
import ImportantInfo from "../../components/landingPage/ImportantInfo";
import Contact from "../../components/landingPage/Contact";
import ButtonDrw from "../../components/landingPage/ButtonDrw";

import {
  GTM_WIDEO,
  GTM_CHIRURGIA,
  GTM_DERMATOLOGIA,
  GTM_E_ONKOLOGIA,
  GTM_NIECHIRURGIA,
  GTM_ONKOLOGIA,
  GTM_LEAD_UMOW_SIE,
} from "../../config/gtm_classes";

const GTM_LOCATION = "katowice-usuwanie";

//styles
import "../../styles/home.scss";

import SEO from "../../components/SEO";
import CallCta from "../../components/CallCta";
import ButtonApptNow from "../../components/landingPage/ButtonApptNow";

const REVIEWS = [
  {
    author: "Marcin G.",
    city: "Katowice",
    review:
      "Terminy u tego specjalisty są bardzo szybkie i miło zaskoczyła mnie ciągła dostępność rejestracji. Samo badanie było bardzo profesjonalne i dokładne, co jest dla mnie bardzo istotne, szczególnie ze względu na ilość znamion, które mam na ciele. Choć wizyta trwała nieco ponad 30 minut, to zdecydowanie warta była poświęcenia czasu. Polecam z czystym sumieniem!",
  },
  {
    author: "Przemysław Z.",
    city: "Katowice",
    review:
      "Obsługa rejestracji w tym miejscu jest bardzo pomocna i zaangażowana. Lekarz, który mnie przyjął, również okazał się bardzo miły i kompetentny. Z pewnością można polegać na jego profesjonalizmie. Co ważne, badanie znamion przeprowadzono bardzo dokładnie. Bardzo polecam to miejsce każdemu, kto potrzebuje kompleksowej opieki dermatologicznej.",
  },
  {
    author: "Marta D.",
    city: "Katowice",
    review:
      "Wizyta u Pani Doktor była bardzo pozytywnym doświadczeniem. Lekarka okazała się bardzo miła i kompetentna, co pozwoliło mi poczuć się komfortowo podczas badania. Wszystkie moje pieprzyki zostały dokładnie przebadane. Już umówiłam się na badanie kontrolne, polecam każdemu.",
  },
];

const OFFERS = [
  {
    type: "chirurgiczne",
    title: "Usuwanie znamion",
    desc:
      "Każda niepokojąca zmiana skórna powinna być usunięta chirurgicznie gdyż tylko na podstawie badania histopatologicznego można postawić jednoznaczną diagnozę. Przed wykonaniem zabiegu pacjent powinien poddać się badaniu dermatoskopowemu.",
      link:"#cennik",
  },
  {
    type: "badanie",
    title: "Badanie znamion",
    desc:
      "Dermatoskopowe badanie wszystkich znamion na skórze w formie wideodermatoskopii z mapowaniem zmian wytypowanych przez lekarza do obserwacji oraz archiwizacją wykonanych zdjęć.",
      link:"/katowice/dermatoskopowe-badanie-znamion-wideodermatoskopia",
  },
  {
    type: "onkologia",
    title: "Konsultacje onkologiczne",
    desc:
      "Konsultacja onkologiczna w zakresie nowotworów skóry, w tym czerniaka złośliwego. Pacjent otrzymuje zalecenia po usunięciu zmiany nowotworowej, dotyczące badań kontrolnych, leczenia uzupełniającego, a także leczenia zaawansowanego czerniaka.",
      link: "/poznan/konsultacje-onkologiczne-leczenie-czerniaka"
  },
];

const PRICES = [
  {
    isOpened: true,
    categoryName: "Chirurgiczne usuwanie znamion",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_CHIRURGIA}`}
        data-apptnow-widget="0c307a70-bf61-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "200",
        label: "konsultacja chirurgiczna",
        sub: "Zabiegi chirurgiczne są wykonywane u pacjentów pełnoletnich",
      },
      {
        price: "550",
        label: "usunięcie 1 znamienia do 1,5cm",
        sub:
          "w standardowej lokalizacji - tułów/kończyny - w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "330",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty do 1,5cm",
        sub: "w standardowej lokalizacji - tułów/kończyny",
      },
      {
        price: "650",
        label:
          "usunięcie 1 znamienia powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
        sub: "w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "380",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
      },
      {
        price: "150",
        label: "badanie histopatologiczne jednej zmiany",
      },
      {
        price: "0",
        label: "wizyta kontrolna po zabiegu ze zdjęciem szwów",
        sub: "*dla pacjentów, którzy odbyli zabieg w Twoje Znamiona",
      },
      {
        price: "100",
        label: "zdjęcie szwów",
        sub: "*dla pacjentów, którzy wykonali zabieg poza Twoje Znamiona",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Badanie znamion - wideodermatoskopia",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_WIDEO}`}
        data-apptnow-widget="da393020-bf60-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "450",
        label: "wideodermatoskopia wszystkich znamion - osoba dorosła ",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "350",
        label:
          "wideodermatoskopia wszystkich znamion - dziecko do 12 roku życia",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "250",
        label:
          "wideodermatoskopia - wizyta kontrolna do 6 mies. od pełnego badania",
        sub:
          "badanie wytypowanych przez lekarza na poprzedniej wizycie znamion do monitorowania",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje onkologiczne - czerniak",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_E_ONKOLOGIA}`}
        data-apptnow-widget="57c029a0-9b25-11ed-b366-89b05425b063"
      />
    ),
    items: [
      {
        price: "300",
        label: "e-wizyta - wideokonferencja",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje dermatologiczne",
    items: [
      {
        price: "250",
        label: "konsultacja dermatologiczna",
        sub: "inne schorzenia skóry: trądzik, łuszczyca, grzybica itp.",
        button: () => (
          <ButtonApptNow
            className={`${GTM_LOCATION} ${GTM_DERMATOLOGIA}`}
            data-apptnow-widget="4c45b050-8854-11ee-9f34-715aa310d7de"
          />
        ),
      },
    ],
  },
];

const INFOS = [
  {
    title: "Ile trwa zabieg?",
    desc:
      "Zabieg chirurgicznego usuwania znamion jest krótki, nie wymaga pobytu w szpitalu, trwa zwykle ok. 20-30 min.",
  },
  {
    title: "Czy usuwanie znamion boli?",
    desc:
      "Zabieg usuwania znamion odbywa się w znieczuleniu miejscowym przy zachowaniu pełnej świadomości pacjenta.",
  },
  {
    title: "Jak duża będzie blizna?",
    desc:
      "Wielkość powstałej po zabiegu blizny jest uzależniona od wielkości znamienia oraz przestrzegania zaleceń pozabiegowych.",
  },
];

const CtaUmowSie = () => {
  return (
    <AnchorLink href="#cennik" title="Umów się" className={GTM_LEAD_UMOW_SIE}>
      Umów się
    </AnchorLink>
  );
};
const CtaCennik = () => {
  return (
    <AnchorLink href="#cennik" title="Cennik">
      Ile to kosztuje?
    </AnchorLink>
  );
};

const KatowiceUsuwanieZnamionPage = ({ graph, location }) => {
  const FAQS = [
    {
      image: graph.faqs[1].image,
      imageMobile: graph.faqs[1].imageMobile,
      title: "Czym jest badanie histopatologiczne?",
      text:
        "Badanie histopatologiczne polega na badaniu mikroskopowym pobranego materiału histologicznego (fragmentu tkanki). Otrzymane preparaty oglądane są przez patomorfologa, który na podstawie ocenianego obrazu oraz wykonanych testów w tym badań immunohistochemicznych stawia szczegółowe rozpoznanie.",
    },
    {
      image: graph.faqs[2].image,
      imageMobile: graph.faqs[2].imageMobile,
      title: "Czy mogę usunąć znamię laserowo?",
      text:
        "Usunięcie laserowe jest możliwe jeśli po uprzednim badaniu dermatoskopowym w ocenie lekarza znamię nie budzi niepokoju onkologicznego. Usuwając znamiona laserem musimy pamiętać, że pozbawiamy się możliwości wykonania badania histopatologicznego.",
    },
  ];

  return (
    <Layout bg_color="blue" constactCtaComponent={<CtaUmowSie />} location={location}>
      <SEO
        title="Centrum Usuwania Znamion i Pieprzyków w Katowicach - twojeznamiona.pl"
        description="Katowice - Usuwanie Znamion. Czy wiesz, że aby bezpiecznie usunąć znamię należy je wcześniej zbadać? Chirurgiczne usuwanie niepokojących pieprzyków. Laserowe usuwanie łagodnych zmian skórnych. Ile to kosztuje - cennik. Umów się!"
        keywords="badanie znamion, katowice, dermatoskopia"
      />
      <Hero
        h1="Katowickie Centrum Badania i Usuwania Znamion"
        oneLiner="Wszystkie swoje działania skupiamy na kompleksowej diagnostyce znamion, aby wyeliminować nowotwory skóry z życia milionów ludzi."
        oneLinerMore="Upewnij się, że czerniak nie ukrywa się w jednym z niepozornych pieprzyków na Twojej skórze."
        ctaPrimary={<CtaUmowSie />}
        ctaSecondary={<CtaCennik />}
        type="surgery"
      />
      <Offer
        className="home_offer"
        h5="W naszym Centrum Badania i Usuwania Znamion w procesie diagnostycznym uczestniczą dermatolodzy, chirurdzy oraz onkolodzy, zapewniając pacjentom kompleksową opiekę i profesjonalną  diagnostykę."
        data={OFFERS}
      />
      <Surgery2 className="home_two_col_section_left_img" reverse={false} />

      <PriceList className="home_price_list" data={PRICES} />
    
      <Contact className="home_contact" where="w Katowicach" city="Katowice Chirurgia" />
      <Location
        className="home_location"
        coords={{
          lat: 50.23645088235344,
          lng: 19.0037160197343, 
        }}
        defaultZoom={14}
        googleMarker={{
          data: ["ul. Kawek 2a/2", "40-534 Katowice"],
          link: "https://maps.app.goo.gl/7cZUEGRxAc46KTDM8",
        }}
        additionalDetails="Centrum Badania Znamion znajduje się przy ul. Kawek 2a/2 w Katowicach, w dzielnicy Brynów.  Parking ogólnodostępny przy budynku."
        locationInfo={[
          "Puchała Clinic ",
          "ul. Kawek 2a/2",
          "40-534 Katowice",
        ]}
        hoursInfo={["pon. - pt.: 12:00⁃20:00"]}
        transportationInfo={[
          "Ośrodek znajduje się w bliskiej okolicy restauracji La Cucina oraz sklepu Lidl",
        ]}
      />
      <Gallery
        className="home_gallery"
        data={graph.gallery}
        city="katowice"
        imageTitlePostfix="w Katowicach"
      />
      <Melanoma className="home_melanoma" />

      <Reviews className="home_reviews " data={REVIEWS} />
      <ImportantInfo
        className="home_important_info "
        h2="Chirurgiczne usuwanie pieprzyków"
        data={INFOS}
      />

      <Laser
        className="home_two_col_section_left_img with_no_padding_top"
        reverse={false}
      />

      <Faq className="home_faq" data={FAQS} />
      <Dermoscopy className="home_two_col_section_right_img" reverse={false} />
      <Oncology className="home_two_col_section_left_img with_no_padding_bottom" />
      <Cta className="home_cta" constactCtaComponent={<CtaUmowSie />} />
      <CallCta />
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        ImageGallery1: allFile(
          filter: { relativePath: { regex: "/katowice1.jpg/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery2: allFile(
          filter: { relativePath: { regex: "/katowice2.jpg/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1Image: allFile(filter: { relativePath: { regex: "/faq3.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2Image: allFile(filter: { relativePath: { regex: "/faq4.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq3Mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq4Mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <KatowiceUsuwanieZnamionPage
        graph={{
          gallery: [
            data.ImageGallery1.nodes[0],
            data.ImageGallery2.nodes[0],
          
          ],
          faqs: {
            1: {
              image: data.Faq1Image.nodes[0],
              imageMobile: data.Faq1ImageMobile.nodes[0],
            },
            2: {
              image: data.Faq2Image.nodes[0],
              imageMobile: data.Faq2ImageMobile.nodes[0],
            },
          },
        }}
        {...props}
      />
    )}
  />
);
